import axios from 'axios';
import { refreshAccessToken } from '../Auth/authUtils';
import config from "../../config";

const axiosInstance = axios.create({
    baseURL: config.apiBaseUrl
});

// Request interceptor to add token to headers
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Response interceptor to handle token refresh on 401
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const refreshToken = await refreshAccessToken();
                localStorage.setItem('access_token', refreshToken);
                axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + refreshToken;
                return axiosInstance(originalRequest);
            } catch (refreshError) {
                // Handle refresh token failure by clearing tokens and redirecting to login
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                window.location.href = '/login'; // Redirect to login page
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
