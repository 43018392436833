import React, {useState} from "react";
import {Container, Row, Col, Card, Form, Button, Spinner} from "react-bootstrap";
import axiosInstance from "../../Components/Requests/Axios";
import DataTable from "../../Components/Tables/DataTable";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

import "./Catalogue.css";
import Graphs from "../../Components/Graphs/Graphs";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface MetadataResponse {
    symbol: string;
    fact_name: string;
    total_records: number;
    date_range: { start_date: string | null; end_date: string | null };
    fact_catalog: { fact_name: string; record_count: number }[];
}

const DataCatalogue: React.FC = () => {
    const [symbol, setSymbol] = useState<string>("AAPL");
    const [factName, setFactName] = useState<string | undefined>(undefined);
    const [startDate, setStartDate] = useState<string | undefined>(undefined);
    const [endDate, setEndDate] = useState<string | undefined>(undefined);
    const [metadata, setMetadata] = useState<any>(null);
    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedFactData, setSelectedFactData] = useState<any>(null);

    const getTransformedData = (rawData: any[]) => {
        if (!rawData || rawData.length === 0) return [];

        // Step 1: Filter valid duration values (3-month restriction) and include all instantaneous values
        const filteredData = rawData.filter((fact: any) => {
            const startDate = fact.start_date ? new Date(fact.start_date) : null;
            const endDate = fact.end_date ? new Date(fact.end_date) : null;

            if (startDate && endDate) {
                const diffInDays = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
                return diffInDays <= 90; // Ensure duration is 3 months or less
            }

            // Include instantaneous values (start_date = null)
            return !startDate;
        });

        // Step 2: Group data by `symbol_id`, `fact_name`, `start_date`, and `end_date`
        const groupedData = filteredData.reduce((acc: Record<string, any[]>, fact: any) => {
            // Construct the group key, handling nulls for `start_date` and `end_date`
            const groupKey = `${fact.symbol_id}-${fact.fact_name}-${fact.start_date || "null"}-${fact.end_date || "null"}`;
            if (!acc[groupKey]) {
                acc[groupKey] = [];
            }
            acc[groupKey].push(fact);
            return acc;
        }, {});

        // Step 3: Deduplicate within each group by selecting the most recent `filed_date`
        const deduplicatedData = Object.values(groupedData).map((group: any[]) => {
            // Sort the group by `filed_date` descending
            group.sort((a, b) => new Date(b.filed_date).getTime() - new Date(a.filed_date).getTime());

            // Return the first (most recent) record in the group
            return {
                fact_name: group[0].fact_name,
                start_date: group[0].start_date, // Preserve start_date
                end_date: group[0].end_date, // Preserve end_date
                filed_date: group[0].filed_date, // Preserve filed_date
                value: group[0].value,
                fiscal_year: group[0].fiscal_year,
                fiscal_period: group[0].fiscal_period,
                form: group[0].form,
                accn: group[0].accn,
            };
        });

        // Step 4: Sort the deduplicated data by `fiscal_year` and `fiscal_period`
        return deduplicatedData.sort((a: any, b: any) => {
            if (a.fiscal_year === b.fiscal_year) {
                return a.fiscal_period.localeCompare(b.fiscal_period);
            }
            return a.fiscal_year - b.fiscal_year;
        });
    };


    const handleRowClick = async (rowData: any) => {
        const {fact_name} = rowData;
        try {
            const response = await axiosInstance.get("/stocks/facts", {
                params: {symbol, fact_name, start_date: startDate, end_date: endDate},
            });
            const rawFactData = response.data;

            // Transform the data for charts
            const transformedData = getTransformedData(rawFactData);
            console.log(transformedData)
            setSelectedFactData({
                full: rawFactData, // Raw data for the table
                transformed: transformedData, // Transformed data for the charts
            });
        } catch (error) {
            console.error("Error fetching financial facts:", error);
            setSelectedFactData(null);
        }
    };

    const fetchMetadata = async () => {
        setIsLoading(true);
        try {
            const params: any = {symbol};
            if (factName) params.fact_name = factName;
            if (startDate) params.start_date = startDate;
            if (endDate) params.end_date = endDate;

            const response = await axiosInstance.get<MetadataResponse>("/metadata", {params});
            setMetadata(response.data);
            setError("");
        } catch (err) {
            setError("Failed to fetch metadata. Please check your inputs.");
            setMetadata(null);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        fetchMetadata();
    };

    return (
        <Container fluid className="metadata-explorer">
            <Row>
                {/* Documentation Section */}
                <Col xs={3} className="docs-column">
                    <Card className="card-custom mb-3">
                        <Card.Body>
                            <Card.Title>Search Engine Overview</Card.Title>
                            <Card.Text>
                                The Data Catalogue allows you to search for financial data across companies. Enter a
                                stock
                                symbol (e.g., AAPL) to explore the breadth of data available for analysis.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="card-custom mb-3">
                        <Card.Body>
                            <Card.Title>Viewing Historical Values</Card.Title>
                            <Card.Text>
                                Click on any row in the search results to view historical values for a given fact. This
                                provides a detailed time series, letting you analyze trends over time.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="card-custom mb-3">
                        <Card.Body>
                            <Card.Title>Understanding Data Redundancy</Card.Title>
                            <Card.Text>
                                Some metrics appear multiple times for a given period because they are referenced in
                                subsequent reports. This provides historical context and performance tracking over time.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="card-custom">
                        <Card.Body>
                            <Card.Title>Raw Data Explanation</Card.Title>
                            <Card.Text>
                                All data presented in the catalogue reflects raw, as-reported values. Certain metrics,
                                such
                                as EPS, may be affected by corporate actions like stock splits.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Main Content Section */}
                <Col xs={9}>
                    {/* Search Bar and Metadata Summary */}
                    <Row className="mb-4">
                        <Col xs={6}>
                            <Card className="card-custom">
                                <Card.Body>
                                    <Card.Title className="text-center">Search Available Data</Card.Title>
                                    <Form onSubmit={handleSearch}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Symbol</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={symbol}
                                                onChange={(e) => setSymbol(e.target.value.toUpperCase())}
                                                placeholder="Enter stock symbol (e.g., AAPL)"
                                                required
                                            />
                                        </Form.Group>
                                        <Button variant="primary" type="submit" className="w-100">
                                            Search
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        {metadata && (
                            <Col xs={6}>
                                <Card className="card-custom">
                                    <Card.Body>
                                        <Card.Title className="text-center">Metadata Summary</Card.Title>
                                        <table className="table table-bordered">
                                            <tbody>
                                            <tr>
                                                <td>Symbol</td>
                                                <td>{metadata.symbol}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Records</td>
                                                <td>{metadata.total_records}</td>
                                            </tr>
                                            <tr>
                                                <td>Date Range</td>
                                                <td>
                                                    {metadata.date_range.start_date || "N/A"} to{" "}
                                                    {metadata.date_range.end_date || "N/A"}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}
                    </Row>

                    {/* Fact Catalog */}
                    {metadata && (
                        <Row className="mb-4">
                            <Col>
                                <Card className="card-custom">
                                    <Card.Body>
                                        <Card.Title>Fact Catalog</Card.Title>
                                        <div className="data-table-container">
                                            <DataTable
                                                data={
                                                    metadata.fact_catalog
                                                        ? [...metadata.fact_catalog].sort(
                                                            (a, b) => b.record_count - a.record_count
                                                        )
                                                        : []
                                                }
                                                rowsPerPage={10}
                                                sortable={true}
                                                searchable={true}
                                                onRowClick={handleRowClick}
                                            />
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )}

                    {/* Visualization Section */}
                    {selectedFactData && (
                        <Row>
                            <Col>
                                <Card className="card-custom visualization-section">
                                    <Card.Body>
                                        <Card.Title>Visualizations</Card.Title>
                                        <Graphs data={selectedFactData.transformed || []}/>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </Container>
    );
};
export default DataCatalogue;
