import React, { useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Tabs, Tab } from "react-bootstrap";
import DataTable from "../Tables/DataTable"; // Import your DataTable component
import {
    Chart,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

// Register required Chart.js components
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const Visualization: React.FC<{ data: any[] }> = ({ data }) => {
    const [selectedTab, setSelectedTab] = useState<string>("timeSeries");

    if (!data || !Array.isArray(data) || data.length === 0) {
        return <p>No data available for visualization.</p>;
    }

    // Ensure all items in `data` have `end_date` and `value` properties
    const validatedData = data
        .filter((item: any) => item.end_date && item.value != null)
        .sort((a, b) => new Date(a.end_date).getTime() - new Date(b.end_date).getTime()); // Sort by ascending end_date

    if (validatedData.length === 0) {
        return <p>No valid data available for visualization.</p>;
    }

    // Time Series Data Configuration
    const timeSeriesData = {
        labels: validatedData.map((item: any) => item.end_date), // Use end_date for x-axis
        datasets: [
            {
                label: "Value",
                data: validatedData.map((item: any) => item.value),
                borderColor: "blue",
                backgroundColor: "rgba(135, 206, 235, 0.5)",
                tension: 0.3, // Smooth out the lines
            },
        ],
    };

    // Bar Chart Data Configuration
    const barChartData = {
        labels: validatedData.map((item: any) => item.end_date), // Use end_date for x-axis
        datasets: [
            {
                label: "Value",
                data: validatedData.map((item: any) => item.value),
                backgroundColor: "rgba(75, 192, 192, 0.6)",
            },
        ],
    };

    return (
        <Tabs
            activeKey={selectedTab}
            onSelect={(k) => setSelectedTab(k || "timeSeries")}
            className="mb-3"
        >
            {/* Time Series Tab */}
            <Tab eventKey="timeSeries" title="Time Series">
                <Line
                    data={timeSeriesData}
                    options={{
                        responsive: true,
                        plugins: {
                            legend: { display: true, position: "top" },
                            title: { display: true, text: "Time Series Data" },
                        },
                        scales: {
                            x: { title: { display: true, text: "End Date" } },
                            y: { title: { display: true, text: "Value" } },
                        },
                    }}
                />
            </Tab>

            {/* Bar Chart Tab */}
            <Tab eventKey="barChart" title="Bar Chart">
                <Bar
                    data={barChartData}
                    options={{
                        responsive: true,
                        plugins: {
                            legend: { display: true, position: "top" },
                            title: { display: true, text: "Bar Chart Data" },
                        },
                        scales: {
                            x: { title: { display: true, text: "End Date" } },
                            y: { title: { display: true, text: "Value" } },
                        },
                    }}
                />
            </Tab>

            {/* Data Table Tab */}
            <Tab eventKey="dataTable" title="Data Table">
                <DataTable
                    data={validatedData}
                    rowsPerPage={10}
                    sortable={true}
                    searchable={true}
                />
            </Tab>
        </Tabs>
    );
};

export default Visualization;
