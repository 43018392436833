import React from 'react';
import { Card, Tabs, Tab } from 'react-bootstrap';
import './Fundamentals.css';

const FundamentalsDocumentation = () => {
    return (
        <div className="fundamentals-documentation">
            <h1>Financials API Documentation</h1>

            {/* Overview Card */}
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Interfacing with Financial Data</Card.Title>
                    <p>
                        Our API provides access to financial data across multiple symbols and financial metrics. The
                        <strong> /financials </strong> endpoint allows you to retrieve data with options to filter by
                        symbol, fact name, and date range.
                    </p>
                    <p>
                        <strong>Note:</strong> The <code>symbol</code> parameter is mandatory for all requests.
                        Additional filtering can be applied using <code>fact_name</code>, <code>start_date</code>, <code>end_date</code>, and <code>latest_n_records</code>.
                    </p>
                </Card.Body>
            </Card>

            {/* Endpoints Card */}
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Endpoint</Card.Title>
                    <ul>
                        <li>
                            <code>/financials</code> - Retrieve financial records for a given symbol with optional filters for fact name and date range.
                        </li>
                    </ul>
                </Card.Body>
            </Card>

            {/* Query Parameters Card */}
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Query Parameters</Card.Title>
                    <p>Below are the query parameters supported by the <code>/financials</code> endpoint:</p>
                    <ul>
                        <li>
                            <strong>symbol</strong> (required): Stock symbol to filter data (e.g., "AAPL").
                        </li>
                        <li>
                            <strong>fact_name</strong> (optional): Specific fact name to filter data (e.g., "NetIncomeLoss").
                        </li>
                        <li>
                            <strong>start_date</strong> (optional): Start date for filtering data in YYYY-MM-DD format.
                        </li>
                        <li>
                            <strong>end_date</strong> (optional): End date for filtering data in YYYY-MM-DD format.
                        </li>
                        <li>
                            <strong>latest_n_records</strong> (optional): Number of most recent records to retrieve.
                        </li>
                    </ul>
                </Card.Body>
            </Card>

            {/* Example API Requests */}
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Example API Requests</Card.Title>
                    <Tabs defaultActiveKey="curl" id="api-examples-tabs">
                        {/* cURL Examples */}
                        <Tab eventKey="curl" title="cURL">
                            <h4>Fetch Data for a Symbol (e.g., "AAPL")</h4>
                            <pre>{`curl -X GET "https://api.equityexplorer.io/financials?symbol=AAPL" \\
-H "X-API-KEY: YOUR_API_KEY"`}</pre>

                            <h4>Filter by Fact Name (e.g., "NetIncomeLoss")</h4>
                            <pre>{`curl -X GET "https://api.equityexplorer.io/financials?symbol=AAPL&fact_name=NetIncomeLoss" \\
-H "X-API-KEY: YOUR_API_KEY"`}</pre>

                            <h4>Filter by Date Range</h4>
                            <pre>{`curl -X GET "https://api.equityexplorer.io/financials?symbol=AAPL&start_date=2022-01-01&end_date=2022-12-31" \\
-H "X-API-KEY: YOUR_API_KEY"`}</pre>
                        </Tab>

                        {/* Python Examples */}
                        <Tab eventKey="python" title="Python">
                            <h4>Fetch Data for a Symbol (e.g., "AAPL")</h4>
                            <pre>{`import requests

url = 'https://api.equityexplorer.io/financials'
headers = {'X-API-KEY': 'YOUR_API_KEY'}
params = {'symbol': 'AAPL'}

response = requests.get(url, headers=headers, params=params)
print(response.json())`}</pre>

                            <h4>Filter by Fact Name (e.g., "NetIncomeLoss")</h4>
                            <pre>{`import requests

url = 'https://api.equityexplorer.io/financials'
headers = {'X-API-KEY': 'YOUR_API_KEY'}
params = {'symbol': 'AAPL', 'fact_name': 'NetIncomeLoss'}

response = requests.get(url, headers=headers, params=params)
print(response.json())`}</pre>

                            <h4>Filter by Date Range</h4>
                            <pre>{`import requests

url = 'https://api.equityexplorer.io/financials'
headers = {'X-API-KEY': 'YOUR_API_KEY'}
params = {'symbol': 'AAPL', 'start_date': '2022-01-01', 'end_date': '2022-12-31'}

response = requests.get(url, headers=headers, params=params)
print(response.json())`}</pre>
                        </Tab>

                        {/* JavaScript Examples */}
                        <Tab eventKey="javascript" title="JavaScript">
                            <h4>Fetch Data for a Symbol (e.g., "AAPL")</h4>
                            <pre>{`const axios = require('axios');

const url = 'https://api.equityexplorer.io/financials';
const headers = { 'X-API-KEY': 'YOUR_API_KEY' };
const params = { symbol: 'AAPL' };

axios.get(url, { headers, params })
    .then(response => console.log(response.data))
    .catch(error => console.error(error));`}</pre>

                            <h4>Filter by Fact Name (e.g., "NetIncomeLoss")</h4>
                            <pre>{`const axios = require('axios');

const url = 'https://api.equityexplorer.io/financials';
const headers = { 'X-API-KEY': 'YOUR_API_KEY' };
const params = { symbol: 'AAPL', fact_name: 'NetIncomeLoss' };

axios.get(url, { headers, params })
    .then(response => console.log(response.data))
    .catch(error => console.error(error));`}</pre>

                            <h4>Filter by Date Range</h4>
                            <pre>{`const axios = require('axios');

const url = 'https://api.equityexplorer.io/financials';
const headers = { 'X-API-KEY': 'YOUR_API_KEY' };
const params = { symbol: 'AAPL', start_date: '2022-01-01', end_date: '2022-12-31' };

axios.get(url, { headers, params })
    .then(response => console.log(response.data))
    .catch(error => console.error(error));`}</pre>
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Card>
        </div>
    );
};

export default FundamentalsDocumentation;
