import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axiosInstance from '../../Components/Requests/Axios';
import DataTable from '../../Components/Tables/DataTable';
import './Account.css';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface AccountInfo {
    username: string;
    email: string;
    api_key: string;
    usage: {
        [key: string]: number; // Maps endpoint names to usage counts
    };
}

const Account: React.FC = () => {
    const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);
    const [error, setError] = useState<string>('');
    const hasFetchedData = useRef(false);
    const navigate = useNavigate();
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (hasFetchedData.current) return;

        const fetchAccountInfo = async () => {
            hasFetchedData.current = true;
            try {
                const response = await axiosInstance.get<AccountInfo>(`/account`);
                setAccountInfo(response.data);
            } catch (err) {
                setError('Failed to fetch account information.');
            }
        };

        fetchAccountInfo();
    }, [navigate]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!accountInfo) {
        return <div>Loading account information...</div>;
    }

    const usageData = Object.entries(accountInfo.usage)
        .map(([route, count]) => ({
            Route: route.replace(/_/g, ' '), // Format route names for display
            'Records Consumed': count,
        }))
        .sort((a, b) => b['Records Consumed'] - a['Records Consumed']); // Sort by usage count

    // Filter out metadata for pricing calculation and chart
    const nonMetadataUsageData = usageData.filter(
        (item) => !item.Route.toLowerCase().includes('metadata')
    );

    // Prepare chart data for the top 10 most used non-metadata endpoints
    const chartData = nonMetadataUsageData.slice(0, 10); // Top 10 endpoints excluding metadata
    const data = {
        labels: chartData.map((item) => item.Route),
        datasets: [
            {
                label: 'API Usage Count',
                data: chartData.map((item) => item['Records Consumed']),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    const calculateBill = (usageCounts: number[]) => {
        const totalRecords = usageCounts.reduce((acc, count) => acc + count, 0);
        const cost = totalRecords / 10000; // Update to $1 per 10,000 records
        return cost.toFixed(2);
    };

    // Calculate the bill excluding metadata usage
    const currentBill = calculateBill(
        nonMetadataUsageData.map((item) => item['Records Consumed'])
    );

    return (
        <Container className="account-container">
            <Row>
                <Col>
                    <Card className="mb-3 card-custom">
                        <Card.Body>
                            <Card.Title className="text-center mb-4">Account Information</Card.Title>
                            <p>
                                <strong>Username:</strong> {accountInfo.username}
                            </p>
                            <p>
                                <strong>Email:</strong> {accountInfo.email}
                            </p>
                            <p>
                                <strong>API Key:</strong>
                            </p>
                            <div
                                className="api-key-container"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#f8f9fa',
                                    padding: '10px',
                                    borderRadius: '5px',
                                }}
                            >
                                <pre
                                    style={{
                                        marginBottom: '0',
                                        overflowX: 'auto',
                                        backgroundColor: 'transparent',
                                    }}
                                >
                                    {accountInfo.api_key}
                                </pre>
                                <CopyToClipboard text={accountInfo.api_key} onCopy={() => setCopied(true)}>
                                    <Button variant="outline-secondary" size="sm" style={{ marginLeft: '10px' }}>
                                        {copied ? 'Copied!' : 'Copy'}
                                    </Button>
                                </CopyToClipboard>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mb-3 card-custom">
                        <Card.Body>
                            <Card.Title>API Usage</Card.Title>
                            {/* Updated DataTable with built-in pagination */}
                            <DataTable data={usageData} rowsPerPage={10} />
                            <div className="chart-container mt-4">
                                <Bar className="bar-chart" data={data} options={options} />
                            </div>
                            <h3 className="mt-3">Current Bill: ${currentBill}</h3>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Account;
