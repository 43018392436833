import React, {useState, useMemo} from "react";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";

interface DataTableProps {
    data: Array<{ [key: string]: any }>;
    rowsPerPage?: number;
    sortable?: boolean;
    searchable?: boolean;
    onRowClick?: (rowData: { [key: string]: any }) => void;
}

const DataTable: React.FC<DataTableProps> = ({
                                                 data,
                                                 rowsPerPage = 10,
                                                 sortable = true,
                                                 searchable = false,
                                                 onRowClick,
                                             }) => {
    const [sortKey, setSortKey] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchQuery, setSearchQuery] = useState<string>("");

    if (data.length === 0) {
        return <p>No data available.</p>;
    }

    const headers = Object.keys(data[0]);

    // Filter data based on search query
    const filteredData = useMemo(() => {
        if (!searchQuery) return data;
        return data.filter((row) =>
            Object.values(row).some((value) =>
                value.toString().toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    }, [data, searchQuery]);

    // Sort data globally
    const sortedData = useMemo(() => {
        if (!sortKey) return filteredData;
        return [...filteredData].sort((a, b) => {
            if (a[sortKey] < b[sortKey]) return sortDirection === "asc" ? -1 : 1;
            if (a[sortKey] > b[sortKey]) return sortDirection === "asc" ? 1 : -1;
            return 0;
        });
    }, [filteredData, sortKey, sortDirection]);

    // Paginate data for the current page
    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        return sortedData.slice(startIndex, endIndex);
    }, [sortedData, currentPage, rowsPerPage]);

    const totalPages = Math.ceil(sortedData.length / rowsPerPage);

    // Get visible pages for pagination (e.g., 5 at a time)
    const getVisiblePages = () => {
        const maxVisiblePages = 5;
        const start = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        const end = Math.min(totalPages, start + maxVisiblePages - 1);

        // Adjust the range to always show `maxVisiblePages` pages
        const adjustedStart = Math.max(1, Math.min(start, end - maxVisiblePages + 1));
        return Array.from({length: Math.min(maxVisiblePages, totalPages)}, (_, i) => adjustedStart + i);
    };

    const visiblePages = getVisiblePages();

    // Handle sorting
    const handleSort = (key: string) => {
        if (sortable) {
            if (sortKey === key) {
                setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
            } else {
                setSortKey(key);
                setSortDirection("asc");
            }
            setCurrentPage(1); // Reset to first page on sort
        }
    };

    return (
        <div>
            {/* Search bar */}
            {searchable && (
                <Form.Group className="mb-3">
                    <Form.Control
                        type="text"
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                            setCurrentPage(1); // Reset to first page on search
                        }}
                        placeholder="Search..."
                    />
                </Form.Group>
            )}

            {/* Data Table */}
            <Table striped bordered hover responsive>
                <thead>
                <tr>
                    {headers.map((header) => (
                        <th
                            key={header}
                            onClick={() => handleSort(header)}
                            style={{cursor: sortable ? "pointer" : "default"}}
                        >
                            {header}
                            {sortable && sortKey === header && (
                                <span>{sortDirection === "asc" ? " 🔼" : " 🔽"}</span>
                            )}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {paginatedData.map((row, index) => (
                    <tr
                        key={index}
                        style={{cursor: onRowClick ? "pointer" : "default"}}
                        onClick={() => onRowClick && onRowClick(row)}
                    >
                        {headers.map((header) => (
                            <td key={header}>{row[header]}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </Table>

            {/* Pagination */}
            <Pagination className="justify-content-center mt-3">
                {/* Previous button */}
                <Pagination.Prev
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                />

                {/* First page button */}
                {visiblePages[0] > 1 && (
                    <>
                        <Pagination.Item onClick={() => setCurrentPage(1)}>1</Pagination.Item>
                        <Pagination.Ellipsis disabled/>
                    </>
                )}

                {/* Visible page buttons */}
                {visiblePages.map((page) => (
                    <Pagination.Item
                        key={page}
                        active={currentPage === page}
                        onClick={() => setCurrentPage(page)}
                    >
                        {page}
                    </Pagination.Item>
                ))}

                {/* Last page button */}
                {visiblePages[visiblePages.length - 1] < totalPages && (
                    <>
                        <Pagination.Ellipsis disabled/>
                        <Pagination.Item onClick={() => setCurrentPage(totalPages)}>
                            {totalPages}
                        </Pagination.Item>
                    </>
                )}

                {/* Next button */}
                <Pagination.Next
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                />
            </Pagination>
        </div>
    );
};

export default DataTable;
