import React from 'react';
import { Container, Row, Col, Card, Table } from 'react-bootstrap';

const MetadataAPIDocs: React.FC = () => {
    return (
        <Container className="metadata-api-docs mt-4">
            <Row>
                <Col>
                    <h1 className="text-center mb-4">Metadata API Documentation</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mb-4">
                        <Card.Body>
                            <h2>Overview</h2>
                            <p>
                                The <code>/metadata</code> endpoint provides access to a catalog of metadata related to financial records.
                                Developers can use this endpoint to explore available financial data for a given stock symbol, filter by
                                specific fact names, and identify date ranges for the data.
                            </p>
                            <p>This API is designed to help users refine queries to match their exact requirements.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mb-4">
                        <Card.Body>
                            <h2>Endpoint Details</h2>
                            <p><strong>Base URL:</strong> <code>https://api.equityexplorer.io</code></p>
                            <h3>GET /metadata</h3>
                            <p>Fetch metadata for a specified symbol, with options to filter by fact name and date ranges.</p>
                            <h4>Query Parameters:</h4>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>Parameter</th>
                                    <th>Type</th>
                                    <th>Required</th>
                                    <th>Description</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td><code>symbol</code></td>
                                    <td><code>string</code></td>
                                    <td>Yes</td>
                                    <td>The stock symbol (e.g., <code>AAPL</code>, <code>MSFT</code>).</td>
                                </tr>
                                <tr>
                                    <td><code>fact_name</code></td>
                                    <td><code>string</code></td>
                                    <td>No</td>
                                    <td>The specific fact name to filter by (e.g., <code>AccountsPayableCurrent</code>).</td>
                                </tr>
                                <tr>
                                    <td><code>start_date</code></td>
                                    <td><code>string</code></td>
                                    <td>No</td>
                                    <td>The start date for filtering records (format: <code>YYYY-MM-DD</code>).</td>
                                </tr>
                                <tr>
                                    <td><code>end_date</code></td>
                                    <td><code>string</code></td>
                                    <td>No</td>
                                    <td>The end date for filtering records (format: <code>YYYY-MM-DD</code>).</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mb-4">
                        <Card.Body>
                            <h2>Example Request</h2>
                            <h4>Request:</h4>
                            <pre>
                                GET /metadata?symbol=AAPL&fact_name=AccountsPayableCurrent&start_date=2022-01-01&end_date=2022-12-31 HTTP/1.1
                                Host: api.equityexplorer.io
                                X-API-KEY: YOUR_API_KEY
                            </pre>
                            <h4>Curl Example:</h4>
                            <pre>
                                curl -X GET "https://api.equityexplorer.io/metadata?symbol=AAPL&fact_name=AccountsPayableCurrent&start_date=2022-01-01&end_date=2022-12-31" \
                                -H "X-API-KEY: YOUR_API_KEY"
                            </pre>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mb-4">
                        <Card.Body>
                            <h2>Response Fields</h2>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>Field</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td><code>symbol</code></td>
                                    <td><code>string</code></td>
                                    <td>The queried stock symbol.</td>
                                </tr>
                                <tr>
                                    <td><code>fact_name</code></td>
                                    <td><code>string</code></td>
                                    <td>The queried fact name or <code>all_records</code>.</td>
                                </tr>
                                <tr>
                                    <td><code>total_records</code></td>
                                    <td><code>number</code></td>
                                    <td>The total number of records available for the symbol under the given constraints.</td>
                                </tr>
                                <tr>
                                    <td><code>date_range</code></td>
                                    <td><code>object</code></td>
                                    <td>The start and end date of available records.</td>
                                </tr>
                                <tr>
                                    <td><code>fact_catalog</code></td>
                                    <td><code>array</code></td>
                                    <td>
                                        A catalog of fact names and their corresponding record counts.
                                        <ul>
                                            <li><code>fact_name</code>: The name of the fact (e.g., <code>AccountsPayableCurrent</code>).</li>
                                            <li><code>record_count</code>: The number of records available for the fact.</li>
                                        </ul>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mb-4">
                        <Card.Body>
                            <h2>Example Response</h2>
                            <pre>
    {JSON.stringify({
        symbol: "AAPL",
        fact_name: "AccountsPayableCurrent",
        total_records: 150,
        date_range: {
            start_date: "2022-01-01",
            end_date: "2022-12-31",
        },
        fact_catalog: [
            {
                fact_name: "AccountsPayableCurrent",
                record_count: 50,
            },
            {
                fact_name: "Net Income",
                record_count: 60,
            },
            {
                fact_name: "Operating Expenses",
                record_count: 40,
            },
        ],
    }, null, 2)}
</pre>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default MetadataAPIDocs;
